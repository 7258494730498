import { css } from 'lit';

/*
This file is reserved for any custom css that developers want to add to
customize their theme. Simply add your css to this file and yarn build.
*/

export default css`
    :host{
        --primary-color-invert:#fff;
        --primary-color-dark:#d56d06;
    }
    input[type="checkbox"]:checked {
        background-color: var(--primary-color);
        border-color: var(--primary-color);
    }
    input[type="checkbox"]:checked::after {
        border: 1px solid var(--primary-color);
    }
    a {
        color: var(--primary-color);
        text-decoration: none;
    }
    .m-markdown-small a, .m-markdown a {
        color: var(--primary-color);
    }
    #api-info {
        margin-left: 0px;
    }
    .m-btn:hover {
        background-color: var(--primary-color-dark);
    }
    .diagrams {
        max-width:700px
    }
    .diagram-container{
        container-type:inline-size;
        display:flex;
    }
    @container (min-width: 700px) {
        .diagrams {
            max-width:50% !important;
        }
    }
    :host {
        --light-fg: #bbbbbb;
    }
    .read-mode {
        margin-top: 30px;
        padding-top: 24px;
        border-top: 1px dashed var(--border-color);
    }
    #link-operations-top,#link-auth{
        display:none;
    }
`;
